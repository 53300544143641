<template>
  <div class="container pd100">
    <div class="card mb10">
      <div class="card-inner">
        <div class="card-title">上课班级：{{ detail.classes_name }}</div>
        <div class="card-txt">课程名称：{{ detail.course_name }}</div>
        <div class="card-txt">上课老师：{{ course_teacher.teacher_name }}</div>
        <div class="card-txt">上课教室：{{ detail.room_name }}</div>
        <div class="card-txt">上课时间：{{ detail.class_date }} {{ detail.class_time }}</div>
      </div>
    </div>
    <van-cell-group class="mb10">
      <van-cell title="上课签到" is-link :to="'/teacher/checkin?course_class_id=' + detail.course_class_id" />
      <van-cell title="学员勋章" is-link :to="'/teacher/achievement?course_class_id=' + detail.course_class_id" />
      <!-- <van-cell title="请假申请" is-link :to="1" /> -->
    </van-cell-group>
    <van-grid class="grid bgf mb10" :border="false" :column-num="2">
      <van-grid-item class="grid-item">
        <div class="grid-item-icon">总</div>
        <div class="grid-item-name">
          <span>学员数</span>
          <span class="ui-color ml5">{{ detail.reserve_count }}</span>
        </div>
      </van-grid-item>
      <van-grid-item class="grid-item">
        <div class="grid-item-icon">假</div>
        <div class="grid-item-name">
          <span>已请假</span>
          <span class="ui-color ml5">{{ detail.cancel_count }}</span>
        </div>
      </van-grid-item>
    </van-grid>
    <div class="studen-list">
      <div v-for="item in course_students" :key="item.student_id" class="studen-list-item">
        <div class="studen-list-item-media">
          <van-image :src="item.student_avatar" class="studen-list-item-media-img" />
        </div>
        <div class="studen-list-item-inner">
          <div class="studen-list-item-title"><span class="studen-list-item-icon" :style="{background:item.student_gender == 1 ? '#5499f6' : '#ffa6ce'}"><van-icon class-prefix="icon" color="#fff" size="12" :name="item.student_gender == 1 ? 'sex_man' : 'sex_woman'" /></span><span>{{ item.student_name }}</span></div>
          <div class="studen-list-item-txt">上课<span class="ml5 mr5">{{ item.book_count }}</span>次</div>
        </div>
      </div>
    </div>
    <loadings :loading='searchLoading' />
  </div>
</template>

<script>
import { Grid, GridItem } from 'vant'
import PageMixin from '@/mixins/page'
import Loadings from '@/components/loading'

export default {
  name: 'TeacherScheduleDetail',
  components: {
    [Grid.name]: Grid,
    loadings:Loadings,
    [GridItem.name]: GridItem
  },
  mixins: [PageMixin],
  data() {
    return {
      searchLoading:false,
      detail: {},
      course_studio: {},
      course_teacher: {},
      course_students: []
    }
  },
  created() {
    this.onDetail()
  },
  methods: {
    onDetail() {
      this.searchLoading = true
      this.$api.employee_scheduledetail({ course_class_id: this.$route.query.course_class_id }).then(res => {
        this.detail = res.data
        this.course_studio = res.data.course_studio
        this.course_students = res.data.course_students
        this.course_teacher = res.data.course_teacher
        this.searchLoading = false
      })
    },
    onSubmit() {
      this.$router.push({ path: '/student/ask-leave?course_class_id=' + this.detail.course_class_id })
    }
  }
}
</script>
<style lang="less" scoped>
  .card{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    background: #fff;
    &-media{
      width: 130px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 10px;
    }
    &-inner{
      flex: 1;
    }
    &-title{
      // max-width: 140px;
      font-size: 14px;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
  }
  .teacher{
    &-inner{
      padding: 10px 15px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 60px;
    }
    &-avatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
    }
    &-title{
      font-size: 14px;
      color: #333;
    }
    &-hd{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &-bd{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      color: #666;
    }
  }

  .studen-list{

  }

  .studen-list-item{
    padding: 10px 15px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-media{
      margin-right: 10px;
      &-img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    &-icon{
      background: #5499f6;
      color: #fff;
      display: inline-block;
      border-radius: 4px;
      width: 16px;
      height: 16px;
      text-align: center;
      line-height: 16px;
      margin-right: 5px;
    }
    &-title{
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
  }

  .grid{
    &-item{
      /deep/ .van-grid-item__content{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }
      &-icon{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #00DE93;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #00DE93;
        margin-bottom: 5px;
        margin-right: 10px;
      }
      &-name{
        font-size: 14px;
        color: #333;
      }
    }
  }
</style>

